import {initInView} from "./helpers";

document.querySelectorAll('.js-carousel-slider').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/carousel/carousel-slider' /* webpackChunkName: "/dist/js/carousel-slider" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-section-info').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/section-info/section-info' /* webpackChunkName: "/dist/js/section-info" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-burger').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/burger/burger' /* webpackChunkName: "/dist/js/burger" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-gallery-grid').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/gallery-grid/gallery-grid' /* webpackChunkName: "/dist/js/gallery-grid" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-google-map').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/google-map/google-map' /* webpackChunkName: "/dist/js/google-map" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-menu-main').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/layout/menu-main/menu-main' /* webpackChunkName: "/dist/js/menu-main" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-popup-trigger').forEach($el => {
    $el.addEventListener('click', event => {
        event.preventDefault();
        import('../../../partials/blocks/popup/popup' /* webpackChunkName: "/dist/js/popup" */).then(({default: init}) => init($el));
    });
});