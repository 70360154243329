export function initInView(el) {
    return new Promise((resolve, reject) => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    resolve(true);
                    observer.unobserve(el);
                }
            });
        });
        observer.observe(el);
    });
}